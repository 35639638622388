import React, { Component } from "react";
import Home from "./Home";
import PesoOnlineLoanAgreement from "./Page/PesoOnlineLoanAgreement";
import PrivacyAgreement from "./Page/PrivacyAgreement";
import ServiceAgreement from "./Page/ServiceAgreement";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

class App extends Component {
  render() {
    console.log("----App----");
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/pesoOnlineLoanAgreement"
          component={PesoOnlineLoanAgreement}
        />
        <Route exact path="/privacyAgreement" component={PrivacyAgreement} />
        <Route exact path="/serviceAgreement" component={ServiceAgreement} />
      </Router>
    );
  }
}

export default App;
