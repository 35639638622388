import React from "react";
import email from "../assets/imgs/email.png";
import emailMobile from "../assets/imgs/mobile/email.png";
function Footer(props) {
  if (props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
        <div className="one-center">
          <div className="title">Regulaciones</div>
          <div className="infosSelf">
            <a href="/pesoOnlineLoanAgreement" target="_blank">
              {" "}
              《PesoOnline Loan Agreement》
            </a>
          </div>
          <div className="infos">
            <a href="/privacyAgreement" target="_blank">
              {" "}
              《Privacy agreement》
            </a>
          </div>
          <div className="infos">
            <a href="/serviceAgreement" target="_blank">
              {" "}
              《service agreement》
            </a>
          </div>
        </div>
        <div className="one-center-end">
          {/* <div className='title'>Codeblock Lending Inc.</div> */}
          <div className="infos">
            <img src={emailMobile} className="contImg" alt="fottOne" />
            <div>service@peso-online.com</div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer id="footer" className={"footer"}>
      <div className="bar">
        <div className="one-center">
          {/* <div className='title'>Codeblock Lending Inc.</div> */}
          <div className="infos">
            <img src={email} className="contImg" alt="fottOne" />
            <div>service@peso-online.com</div>
          </div>
        </div>
        <div className="one-center one-center-end">
          <div className="title">Regulations</div>
          <div className="infos">
            <a href="/pesoOnlineLoanAgreement" target="_blank">
              {" "}
              《PesoOnline Loan Agreement》
            </a>
          </div>
          <div className="infos">
            <a href="/privacyAgreement" target="_blank">
              {" "}
              《Privacy agreement》
            </a>
          </div>
          <div className="infos">
            <a href="/serviceAgreement" target="_blank">
              {" "}
              《service agreement》
            </a>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2020 | CASH AID LOAN ONLINE LENDING INC. | All Rights
        Reserved
      </div>
    </footer>
  );
}

export default Footer;
