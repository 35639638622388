import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="TERMS OF SERVICE" />
        <h2>TERMS OF SERVICE</h2>
        <p>
          By using, accessing, downloading, browsing or registering for the
          PesoOnline Services on the PesoOnline website, PesoOnline mobile
          application (“PesoOnline App”) or other related digital media or
          mediums (including social media) (each and collectively, the
          PesoOnline “Facilities”), you signify that you have read, understood
          and accepted these Terms of Service and agree to be bound by them,
          including all relevant rules and regulations for the use and
          exploitation of any services, products or information on the
          PesoOnline Facilities as they are currently available or may be made
          available in the future. You also agree to comply with all existing or
          hereafter enacted laws, rules, regulations and government
          proclamations applicable to the PesoOnline Facilities or Company (as
          defined below), as well as other terms and conditions governing the
          use of other facilities, benefits, products or services we may provide
          to you in connection with the PesoOnline Services, such as, but not
          limited to, the Members Get Membership Program or other similar
          programs that may be offered in the future. You may be required to
          register for an account (“PesoOnline Account”) in order to use the
          PesoOnline Services. If you access the PesoOnline Services through any
          PesoOnline Facility (whether through the PesoOnline Application),
          these Terms of Service shall become a binding agreement between you
          and Oriente Express Techsystem Corporation ("OETC" or "Company") at
          the time you access the PesoOnline Facility to use the PesoOnline
          Services. If you disagree with, do not fully understand, or have
          reservations about any provision of these Terms of Service, please
          exit and cease using this PesoOnline Facility.
        </p>
        <p></p>
        <p>Eligibility</p>
        <p></p>
        <p>
          The PesoOnline Facility is intended only for individuals who are
          eighteen (18) years of age or older, in good credit, and who have the
          legal capacity to enter into a binding agreement, specifically to use
          the products and/or services offered in the PesoOnline Facility.
        </p>
        <p></p>
        <p>
          By creating and maintaining a PesoOnline account, including using any
          products, services, facilities or benefits in the PesoOnline Facility,
          you represent and warrant that you meet the above eligibility
          requirements. You likewise expressly authorize, consent and permit the
          Company to use all necessary means to verify your identity, conduct
          fraud checks, fraud verifications, background checks, credit and
          background checks, based on the information you provide, or by
          obtaining data from other sources, including any third-party
          information providers. The use of the PesoOnline Facility is also
          subject to the PesoOnline Privacy Policy, which is incorporated herein
          by reference.
        </p>
        <p></p>
        <p>Registration and Application</p>
        <p></p>
        <p>
          When creating your PesoOnline account or applying to become a borrower
          or lender, you agree to provide true, current, complete and accurate
          personal information in the registration or application form, which is
          necessary to use the services under the PesoOnline Facility. The
          Company and its partner lenders reserve the right to conduct
          background verification of your identity, including credit and fraud
          checks, before, during and during your registration, application, use
          of the PesoOnline Facility, any loan or any other service or feature.
          If any information you provide is untrue, inaccurate, not current or
          incomplete, we reserve the right to cancel your registration, suspend
          your PesoOnline account, reject any application you submit and
          restrict your future use of the PesoOnline Facility and the products
          and services offered by the PesoOnline Facility. The Company also
          reserves the right to reject any registration, deny access to the
          PesoOnline Facility and/or reject any loan application or investment
          that violates these Terms of Service.
        </p>
        <p></p>
        <p>
          Before registering and using the PesoOnline Facility, you must
          understand that we will only provide the Services with your consent to
          disclose relevant identity, employment and financial information,
          which must be provided by you and which the Company may also obtain
          from all relevant sources on your behalf in accordance with your
          instructions and authorizations (“Authorizations”).
        </p>
        <p></p>
        <p>
          By accepting and clicking the “Agree” button below, you hereby confirm
          that you have read and accepted the Privacy Policy and consent to the
          collection, storage, processing (including for the purpose of
          developing credit scores and user profiling) and disclosure of the
          information you provide (including with respect to designated related
          party contacts and/or third party contacts or other reference persons)
          or will be provided to third parties such as but not limited to
          lenders, borrowers, third party purchasers of loans (including
          defaulted loans), third party data processors, credit bureaus
          (including Credit Information Company (CIC) and other submitting
          entities with which CIC may share such data as permitted by law),
          credit organizations, as such information is necessary and required
          for access to and use of the PesoOnline Facility and its services.
        </p>
        <p></p>
        <p>
          Please read and confirm your agreement to the Authorization and
          Special Authorization. If you disagree or have reservations about any
          provision of the Authorization and Special Authorization, including
          its scope, coverage or purpose, please exit the PesoOnline Facility
          and cease using the PesoOnline Services.
        </p>
        <p></p>
        <p>General Privacy</p>
        <p></p>
        <p>
          While accessing and using PesoOnline Facilities, you agree that the
          following personal information (hereinafter referred to as “Personal
          Information”) may be collected, used and processed in accordance with
          the PesoOnline Privacy Policy:-
        </p>
        <p></p>
        <p>
          Full name, permanent and residential address, contact number, email
          address, date of birth and/or age, gender, employment information,
          bank account details, credit card and/or financial account
          information, financial history and government issued ID details;
        </p>
        <p></p>
        <p>
          Social media, e-commerce, transportation and other online/mobile
          account details and other information that may not reveal your
          identity or cannot reasonably directly identify you, such as but not
          limited to your access records and information you submit when using
          PesoOnline Facilities;
        </p>
        <p></p>
        <p>
          Information from third parties, including information obtained with
          your authorization from any third party, including your employer
          (“Employment Information”) and other third party sources, which
          contains any financial, transactional or payment records and
          historical transaction information of users;
        </p>
        <p></p>
        <p>
          Traffic, usage, device and other application information generated or
          found in your device and when you use PesoOnline Facilities; and
        </p>
        <p></p>
        <p>
          Anonymous, technical, network and interaction data generated or
          available in the device or any other access medium used to access or
          use PesoOnline Facilities.
        </p>
        <p></p>
        <p>
          The above information will be obtained (from you or on your behalf
          from other sources) when you register with the PesoOnline Facility or
          when you apply to use the products and services of any PesoOnline
          Facility, and will be collected, used, processed, disclosed, retained,
          stored and protected by the Company in accordance with the PesoOnline
          Privacy Policy and these PesoOnline Terms of Service:
        </p>
        <p></p>
        <p>
          A general overview of how Personal Data is processed is provided
          below. Please refer to the PesoOnline Privacy Policy for detailed
          information on the collection, use, processing, sharing, storage and
          transfer of all user data (including Personal Data) through the
          PesoOnline Facility.
        </p>
        <p></p>
        <p>
          Personal Data may be collected through the following means: cookies,
          flash cookies, general login information, information obtained during
          registration for a PesoOnline account, transaction information
          conducted through a PesoOnline account, information obtained from your
          employer, your device (depending on your permission settings), and
          referral information from third-party websites, sources, applications
          and offline sources.
        </p>
        <p></p>
        <p>Personal information may be disclosed to the following parties:</p>
        <p></p>
        <p>
          (a) Company’s affiliates and subsidiaries, agents (including
          collection agencies) and subcontractors, as necessary to conduct the
          Company’s business and on a need to know basis;
        </p>
        <p></p>
        <p>
          (b) Lenders (in the case of Borrowers’ Personal Information) and
          Borrowers (in the case of Lenders’ Personal Information);
        </p>
        <p></p>
        <p>
          (c) Third Party Providers, including those third parties who need the
          information to facilitate lending, including but not limited to
          disbursement, remittance or processing of loans to borrowers, payments
          to lenders, transfers of loans to third parties (i.e., financing
          companies, new lenders, monetary authorities);
        </p>
        <p></p>
        <p>
          (d) Governments, regulators and anti-fraud agencies for the purpose of
          identifying, preventing, detecting, monitoring or addressing suspected
          or actual fraud, money laundering or other criminal activity, and for
          other lawful purposes;
        </p>
        <p></p>
        <p>(e) Marketing with third party partners; and</p>
        <p></p>
        <p>
          (f) Other entities as may be required by law or as may be required in
          the public interest.
        </p>
        <p></p>
        <p>
          If your personal information is shared with any third party for
          purposes not described above, such sharing shall be subject to data
          sharing agreements or outsourcing agreements that require the third
          party to exercise the same care in protecting such personal
          information. Prior to processing your personal information, the
          Company will notify you of such sharing via the email address you
          provided during registration. If you wish to decline to consent to any
          such processing or sharing, such request shall be deemed a request to
          delete your PesoOnline account and terminate your transactions in
          accordance with the terms and conditions of the Agreement accepted and
          executed by you.
        </p>
        <p></p>
        <p>
          The Company may use your personal information or other Internet usage
          data as necessary for the Company to conduct its business, such as,
          but not limited to:
        </p>
        <p></p>
        <p>(i) identifying you as a user of the PesoOnline facility;</p>
        <p></p>
        <p>
          (ii) contacting you regarding your registered PesoOnline account or
          requested information;
        </p>
        <p></p>
        <p>
          (iii) processing your PesoOnline account registration as part of
          investor, borrower and loan application screening;
        </p>
        <p></p>
        <p>(iv) user profiling;</p>
        <p></p>
        <p>(v) generating credit scores;</p>
        <p></p>
        <p>(vi) fraud monitoring, prevention and detection; and</p>
        <p></p>
        <p>(vii) maintaining internal records.</p>
        <p></p>
        <p>
          The Company will retain your personal data for the duration of your
          activities and transactions related to the products and services used;
          for the establishment and due diligence, fraud prevention and
          detection programs, background and credit checks, the provision of new
          products and services, credit scoring and assessment, the exercise or
          defense of legal claims; for legitimate business purposes; or where
          required by law. Your personal data will then be disposed of in a
          secure manner to prevent further processing, unauthorized access or
          disclosure to any other party or the public, or to your interests.
        </p>
        <p></p>
        <p>User Content</p>
        <p></p>
        <p>
          By submitting content to the PesoOnline Facility, you expressly agree
          to the following:
        </p>
        <p></p>
        <p>
          You retain all ownership rights in the content you upload on the
          PesoOnline Facility.
        </p>
        <p></p>
        <p>
          You hereby grant the Company a non-exclusive, transferable,
          sub-licensable, royalty-free, worldwide license to use, reproduce,
          distribute, prepare derivative works, display and perform any
          information or content you provide while using the PesoOnline Facility
          and its services, subject to the privacy provisions in this PesoOnline
          Terms of Service and the PesoOnline Privacy Policy. You grant the
          Company the right to review, delete, edit, reformat, excerpt or
          translate any of your information or content.
        </p>
        <p></p>
        <p>
          You are solely responsible for the content and information you provide
          through or in connection with our products and services. The Company
          assumes no liability for the use or misuse of your personal data by
          others.
        </p>
        <p></p>
        <p>
          All information and content posted on the PesoOnline Facility or
          transmitted privately through the PesoOnline Facility or otherwise in
          connection with the PesoOnline Facility services are the sole
          responsibility of the party from whom the content originated. The
          Company is not responsible for errors or omissions in any information
          or content posted by users.
        </p>
        <p></p>
        <p>
          The Company may access and use information recorded by credit
          reference and fraud prevention agencies to assess lending risk and to
          identify, prevent, detect or combat fraud, money laundering and other
          crimes.
        </p>
        <p></p>
        <p>
          You hereby expressly assume full responsibility and release the
          Company and Oriente Group from any liability to you or any third party
          for any content you create, upload, transmit or display while using
          the PesoOnline Services through the PesoOnline Facility. You also
          promise not to use the PesoOnline Facility to share, create, upload,
          transmit or make available any material, information or content
          protected by copyright, patent, trade secret, trademark, trade name,
          service mark or any property right (including rights of privacy and/or
          publicity) unless you have the necessary licenses, rights, consents
          and permissions to use and authorize the Company and PesoOnline to use
          any and/or all content that you create, upload, transmit or make
          available using the PesoOnline Facility.
        </p>
        <p></p>
        <p></p>
        <p>
          You undertake not to use a false name or conceal your real name in
          order to conceal a crime, evade the execution of a judgment or cause
          damage.
        </p>
        <p></p>
        <p>
          You agree and hereby assume full responsibility for the consequences
          of your actions and interactions using the PesoOnline Services and
          hereby stipulate that the Company and the Oriente Group shall be
          liable for any loss or damage suffered by the Company or the Oriente
          Group as a result of your use or misuse of the PesoOnline Services or
          access to the PesoOnline Facilities.
        </p>
        <p></p>
        <p>Third Party Content and Services</p>
        <p></p>
        <p>
          The PesoOnline Facilities may contain content provided by third party
          services and resources. You acknowledge and agree that we are not
          responsible for the availability, accuracy, appropriateness,
          completeness or non-infringement of third party services, or the
          content, products or services provided through such third party
          services. Unless the Company specifies and/or otherwise notifies you
          of the PesoOnline Services it provides through the PesoOnline
          Facilities, the availability of such third party services does not
          imply our endorsement of the content, products or services provided by
          such third party services. The Company and the PesoOnline Services
          make no representations or warranties regarding any services, content
          and/or other resources provided by third parties. You may not copy,
          delete, alter or obscure any copyright, trademark, service mark or
          other proprietary rights notices contained in or accompanying the
          products or services of any content and/or other resources provided by
          third parties.
        </p>
        <p></p>
        <p>
          We are not responsible for the conduct of any third-party merchants
          submitting any content on their products and services or resources,
          nor do we assume any responsibility to monitor the PesoOnline Services
          for inappropriate content or conduct. We do not and cannot pre-screen
          or monitor all user and partner content. Your use of the PesoOnline
          Services and utilization of products and services provided through the
          PesoOnline Facility is at your own risk. When using the PesoOnline
          Services, you may be exposed to content that does not meet your
          expectations. You assume all risks arising from your reliance on any
          content available in connection with the PesoOnline Services.
        </p>
        <p></p>
        <p>No Warranties; Faults</p>
        <p></p>
        <p>
          The products and services provided on the PesoOnline Facility,
          including products and services provided by third parties through the
          PesoOnline Facility, are provided on an "as is," "as available" basis
          without representations or warranties of any kind. To the maximum
          extent permitted by applicable law, the Company disclaims all such
          warranties, express or implied, including, but not limited to,
          warranties of merchantability, fitness for a particular purpose,
          non-infringement, accuracy, lack of errors, suitability of content,
          availability, payment or transaction fulfillment. The Company, the
          PesoOnline Facility and the PesoOnline Services are not warranted in
          any way.
        </p>
        <p></p>
        <p>
          The Company does not guarantee the content, quality, accuracy,
          adequacy or completeness of the information provided through the
          PesoOnline Facility and expressly disclaims liability for any errors
          or omissions in such information, and in particular for any content
          and/or other resources of products or services provided by third
          parties through or by links to the PesoOnline Facility. The Company
          does not guarantee or warrant that the use of the products and
          services provided through the PesoOnline Facility will produce any
          specific results or that the use of any part or all of the PesoOnline
          Services will be uninterrupted and error-free.
        </p>
        <p></p>
        <p>
          The Company is not responsible for information posted, disclosed,
          published or shared on the PesoOnline Facility by users or merchant
          partners or for any offensive, inappropriate, obscene, illegal or
          otherwise objectionable content uploaded, disclosed, published or
          shared by other users or merchant partners. The Company is not
          responsible for the conduct of any user or merchant partner of the
          PesoOnline Facility (whether online or offline) or for the products or
          services provided through the PesoOnline Facility.
        </p>
        <p></p>
        <p>
          The Company is not responsible for the accuracy or information
          practices of the information, content, products or services provided
          by websites linked to the PesoOnline Facility. As third-party
          websites, platforms or facilities may have different privacy policies
          and/or security standards for their websites, we recommend that you
          carefully review the privacy policies and terms and conditions of
          those websites, platforms or facilities before providing any personal
          data. If you provide any personal data to any third-party website,
          platform or facility, they may process your personal data for their
          own purposes and they shall be responsible for complying with any
          applicable laws related to your personal data and making appropriate
          disclosures to you. If you have any concerns or questions, you are
          advised to contact these third parties directly.
        </p>
        <p></p>
        <p>Partnering Merchants or Third-Party Products and Services</p>
        <p></p>
        <p>
          For third-party products and services purchased through the PesoOnline
          Facility, users should exercise caution and only transact with
          reputable merchants. The Company is not responsible for any
          unauthorized transactions. Once a transaction is authorized, you must
          comply with the merchant's terms of sale, including but not limited to
          how you will receive the goods and/or services you purchased
          (including the validity and expiration date of coupon codes, etc.).
          The Company and Oriente Group shall not be liable for any undelivered
          goods and services, defects, damages and after-sales services of such
          goods and/or third-party services. You shall be solely responsible for
          payment transactions, especially when dealing with merchants who may
          have limited refund policies for invalid disputes.
        </p>
        <p></p>
        <p>Responsibilities for PesoOnline Account</p>
        <p></p>
        <p>
          You are solely responsible for maintaining the confidentiality of your
          username, password, account and any other login or authentication and
          verification information you create or provide to the PesoOnline
          facility, including any mobile one-time password (i.e. a one-time
          password sent to your registered phone number which is valid only for
          one login session or transaction and is used to verify and identify
          you for a certain period of time.) (“Unique User Credentials”). By
          creating, registering and maintaining your account, you agree to
          fulfill the obligations of all activities and engagements performed
          using your PesoOnline account.
        </p>
        <p></p>
        <p>
          You agree and acknowledge that any use of your Unique User Credentials
          shall always be deemed to be accessed by you and, if accessed by a
          third party, shall be subject to your consent and authorization. Any
          and all activities or transactions using your Unique User Credentials
          shall be valid and binding transactions created, committed and
          executed by you.
        </p>
        <p></p>
        <p>
          You hereby undertake to notify the Company immediately of any
          unauthorized use of your account or a security breach.
        </p>
        <p></p>
        <p>Liability for Account Misuse</p>
        <p></p>
        <p>
          The Company is not liable for any losses you may suffer as a result of
          someone else using any of your accounts or unique user credentials,
          whether or not you are aware of it. You may be liable for losses
          suffered by the Company as a result of a third party using your
          account or unique user credentials.
        </p>
        <p></p>
        <p>Account Security</p>
        <p></p>
        <p>
          While the Company has implemented adequate safeguards as required by
          the Data Privacy Act 2012, the Company does not provide any warranty
          or guarantee in this regard. You hereby acknowledge that you provide
          your personal data at your own risk.
        </p>
        <p></p>
        <p>
          You may not share your PesoOnline account or login information, or
          allow anyone to access your PesoOnline account or take any other
          action that may compromise the security of your PesoOnline account.
        </p>
        <p></p>
        <p>
          If you discover or reasonably suspect any security breach, including
          but not limited to the loss, theft or unauthorized disclosure of your
          login information, you must immediately notify us and modify your
          login information. You are solely responsible for maintaining the
          confidentiality of your login information and for all uses of the
          PesoOnline facility and login information, including making purchases
          and loan applications, whether or not authorized by you. You are
          responsible for anything that happens with your PesoOnline account.
        </p>
        <p></p>
        <p>
          We reserve the right to remove or reclaim any username at any time and
          for any reason, including, but not limited to, a claim by a third
          party that the username violates the third party’s rights
        </p>
        <p></p>
        <p>Limitations on Use</p>
        <p></p>
        <p>
          You agree to comply with all applicable terms and conditions, laws and
          regulations in your use of the PesoOnline Facility and in obtaining
          products and services through the PesoOnline Facility. In addition,
          you agree not to do any of the following:
        </p>
        <p></p>
        <p>
          Use one International Mobile Equipment Identity (IMEI) to register
          multiple accounts at any one time, or register an account on behalf of
          an individual other than yourself or any group or entity;
        </p>
        <p></p>
        <p>
          Post or otherwise make available content, or take any action on the
          Site, that may be libelous or defamatory, or infringe or violate the
          rights of others or protected by any copyright or trademark, or
          otherwise violate any law;
        </p>
        <p></p>
        <p>
          Post or otherwise make available content that the Company deems
          objectionable, such as content that is harmful, threatening,
          inflammatory, obscene, fraudulent, invasive of privacy or publicity
          rights, hateful, or otherwise objectionable, or that restricts or
          inhibits any other person from using or enjoying the PesoOnline
          Facilities, or that may expose the Company or users of the PesoOnline
          Facilities to any harm or liability of any type;
        </p>
        <p></p>
        <p>
          Post or otherwise make available any unsolicited or unauthorized
          advertising, solicitation, promotional materials, or any other form of
          solicitation;
        </p>
        <p></p>
        <p>
          Use PesoOnline Information or content on the Facility that sends
          unwelcome messages to any other user;
        </p>
        <p></p>
        <p>
          Impersonate any person or entity, or falsely state or otherwise
          misrepresent yourself, your age, or your affiliation with any person
          or entity;
        </p>
        <p></p>
        <p>
          Post or otherwise make publicly available any third party’s personal
          or financial information on the PesoOnline Facility;
        </p>
        <p></p>
        <p>
          Solicit passwords or personally identifiable information for
          commercial or unlawful purposes;
        </p>
        <p></p>
        <p>
          Use the PesoOnline Facility or our products and services in any manner
          that could damage, disable, overburden, or impair the PesoOnline
          Facility;
        </p>
        <p></p>
        <p>
          Harvest or collect email addresses or other contact information of
          other users from the PesoOnline Facility by electronic or other means,
          including the use of automated scripts; and/or
        </p>
        <p></p>
        <p>
          Post or otherwise make available any material that contains software
          viruses or any other computer code, files, or programs designed to
          interrupt, destroy, or limit the functionality of any computer
          software or hardware or telecommunications equipment.
        </p>
        <p></p>
        <p>Reserved Rights</p>
        <p></p>
        <p>
          We reserve the right, at our sole discretion but without any
          obligation, to:
        </p>
        <p></p>
        <p>
          Deny access to the PesoOnline Facilities and/or discontinue the
          PesoOnline Services or any of its components, temporarily or
          permanently, at any time, to any person, without providing any reason
          and/or prior notice to you. You hereby irrevocably agree, acknowledge
          and warrant that the Company and the Oriente Group shall not be liable
          for any liability (whether in equity or at law) arising or which may
          arise from the denial of access to the PesoOnline Facilities or
          discontinuation of the PesoOnline Services;
        </p>
        <p></p>
        <p>
          Collect, screen, review, mark, filter, modify, block, reject or delete
          any and/or all information provided to the PesoOnline Facilities by
          any user, whether expressly or implicitly, including but not limited
          to hardware information, IP address, information related to browser
          type, cookies, etc. You hereby irrevocably agree, acknowledge and
          warrant that the Company and Oriente Group shall not be liable or
          responsible, whether in equity or at law, arising out of any and/or
          all information provided to or through the PesoOnline Facility by any
          User;
        </p>
        <p></p>
        <p>
          Enhance, improve, develop and introduce new features and
          functionalities of the PesoOnline Service through the PesoOnline
          Facility from time to time without prior notice. You hereby
          understand, agree and acknowledge that any such enhancement,
          improvement, development, new features and/or new functionalities of
          the PesoOnline Facility shall constitute part of the PesoOnline
          Service as defined herein and shall also be subject to these
          PesoOnline Terms of Service and any subsequent amendments or revisions
          thereto, as applicable;
        </p>
        <p></p>
        <p>
          Verify, check, cross-reference, confirm and ascertain the authenticity
          of all information provided by you by obtaining, accessing, retrieving
          or otherwise acquiring similar or additional information provided by
          you to other third party service providers (including, but not limited
          to, telecommunication providers, etc.). You hereby expressly,
          unambiguously and voluntarily permit the Company to request and
          protect such information and expressly, unambiguously and voluntarily
          instruct such third-party providers to: (a) receive and process
          requests for information; (b) always respond favorably to any such
          request by providing the requested information; and (c) provide the
          latter with a certified digital or printed copy of the aforementioned
          information upon request;
        </p>
        <p></p>
        <p>
          Send or cause to be sent to you service updates and/or messages,
          including text messages, notifications, emails and/or any data
          messaging transmissions, informing you about enhancements,
          improvements, developments, features, functionalities, products,
          promotions, offers, advertising and/or any other information regarding
          the PesoOnline Service. The Company and the PesoOnline Service make no
          warranties, express or implied, with respect to such service updates
          and/or messages, but you hereby consent to receive such service
          updates and/or messages and hold harmless the Company and Oriente
          Group from any liability and/or claims for compensation or damages
          arising therefrom;
        </p>
        <p></p>
        <p>
          Set limits and charge fees and applicable taxes for the use of
          PesoOnline Services or PesoOnline Facilities at any time, without
          prior notice (except for required regulatory disclosures that will be
          disclosed to you). You further understand and agree that the Company
          and the Lenders reserve the right, in their sole discretion and
          without obligation, to change the applicable fees, taxes and charges
          imposed for the use of PesoOnline Services at any time and notify you
          in writing prior to the effective date of the change in
          fees/charges/penalties;
        </p>
        <p></p>
        <p>
          Limit the provision, availability, quantity and quality of any
          feature, product or service to any person in the same geographic area,
          demographic profile or any other market, business and/or transaction
          segment. You likewise understand and agree that the Company reserves
          the right, in its sole discretion but without obligation, to manage
          and operate any and/or all PesoOnline Services from any location
          outside of the Republic of the Philippines. You hereby understand,
          agree and assume full responsibility for your continued access to
          PesoOnline Facilities or use of PesoOnline Services, and the results
          or consequences of such access and use, including responsibility for
          compliance with applicable local laws and full responsibility for
          non-compliance or violation of such laws.
        </p>
        <p></p>
        <p>Contracts and Authorizations</p>
        <p></p>
        <p>
          Your use and exploitation of the products and services on the
          PesoOnline Facility may be subject to your consent to certain
          contracts and authorizations. By indicating your consent and
          permission, you agree to be bound by their terms and conditions, and
          such contracts and authorizations shall be deemed validly and
          voluntarily executed by you. Upon request, the Company may provide you
          with electronic copies of such documents.
        </p>
        <p></p>
        <p>Termination</p>
        <p></p>
        <p>
          The Company may immediately terminate or suspend your access to or
          ability to use the PesoOnline Facility, without prior notice or
          liability, for any reason or no reason, including for breach of these
          PesoOnline Terms of Service.
        </p>
        <p></p>
        <p>
          Termination of your access to and use of the PesoOnline Facility will
          not relieve you of any obligations incurred or accrued prior to
          termination, nor will it limit any liability you may have to the
          Company or any third party.
        </p>
        <p></p>
        <p>Limitation of Liability</p>
        <p></p>
        <p>
          Neither the Company, the Lenders nor the Oriente Group shall be liable
          for any loss, expense, compensation, damage or liability suffered by
          you or a third party arising directly or indirectly from any or all of
          the following:
        </p>
        <p></p>
        <p>
          a. Any bank, financial institution, ATM or merchant institution etc.
          refuses to allow, accept or honour the PesoOnline Service;
        </p>
        <p></p>
        <p>
          b. Any bank, financial institution, ATM or merchant institution
          honours the PesoOnline Service; but the transaction is not authorised
          for any reason;
        </p>
        <p></p>
        <p>
          c. User is unable to perform or complete any transaction through the
          use of the PesoOnline Application or any part of the PesoOnline
          Facility due to service/system/line unavailability;
        </p>
        <p></p>
        <p>
          d. Any delay, interruption or termination of transactions, whether due
          to administrative error, technical, mechanical, electrical or
          electronic failure or difficulty or any other cause or circumstance
          beyond the control of the Company and the PesoOnline Service
          (including but not limited to acts of God, strikes, industrial
          disputes, fire, riots, governmental actions, atmospheric conditions,
          third party interference or damage or any change in law);
        </p>
        <p></p>
        <p>
          e. Unauthorised use of any User’s PesoOnline account or PesoOnline any
          losses, expenses, damages or amounts due to any third party by users
          of the Service; or
        </p>
        <p></p>
        <p>
          f. any misrepresentation or fraud or misconduct of any third party.
        </p>
        <p></p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          the Company, its directors, officers, representatives, agents or
          assigns be liable for any direct, special, indirect or consequential
          damages or any other damages of any kind, including, without
          limitation, loss of use, loss of profits or loss of data, whether in
          an action of contract, tort (including, without limitation,
          negligence) or otherwise, arising out of or in connection with the use
          of or inability to use the PesoOnline facilities, including, without
          limitation, any damages caused by reliance by the user on any
          information obtained from the PesoOnline facilities, or by reason of
          mistakes, omissions, interruptions, deletion of files or email,
          errors, defects, viruses, delays in operation or transmission, or any
          failure of performance. You expressly agree that your use of the
          PesoOnline facilities is at your sole risk.
        </p>
        <p></p>
        <p>
          Neither the Company, its directors, officers, representatives, agents
          or assigns shall be liable for any loss or damage you suffer as a
          result of being a member of the PesoOnline Facility, unless such loss
          or damage is caused by our breach of these PesoOnline Terms of Service
          or by the Company’s or its employees’ gross negligence, wilful default
          or fraud. The Company is also not responsible for any breach of these
          PesoOnline Terms of Service caused by circumstances beyond our
          reasonable control.
        </p>
        <p></p>
        <p>Liability for Breach of Contract</p>
        <p></p>
        <p>
          You are liable for any loss or damage suffered by the Company and/or
          its users as a result of:
        </p>
        <p></p>
        <p>
          Your breach of these PesoOnline Terms of Service or any agreement you
          have entered into under the services of the PesoOnline Facility;
        </p>
        <p></p>
        <p>Your fraudulent use of the PesoOnline Facility; and</p>
        <p></p>
        <p>Your provision of inaccurate, false or fraudulent data.</p>
        <p></p>
        <p>Intellectual Property</p>
        <p></p>
        <p>
          The designs, trademarks, service marks and logos of the PesoOnline
          Facility (the “PesoOnline Marks”) are owned by or licensed to the
          Company, and similarly, third-party designs, trademarks, service marks
          and logos may be owned by or licensed to third parties and are subject
          to copyright and other intellectual property rights under the laws of
          the Republic of the Philippines, foreign laws and/or international
          conventions. Unless otherwise expressly permitted, you may not use,
          copy or distribute any PesoOnline Marks or designs, trademarks,
          service marks and/or third-party logos on the PesoOnline Facility.
        </p>
        <p></p>
        <p>Changes to PesoOnline Terms of Service</p>
        <p></p>
        <p>
          The Company reserves the right to change these PesoOnline Terms of
          Service (the “Updated Terms”) at any time. Unless the change to the
          Terms of Service is for legal or administrative reasons, the Company
          will provide reasonable advance notice by posting the Updated Terms on
          the PesoOnline Facility before the Updated Terms become effective.
        </p>
        <p></p>
        <p>
          Your use of the PesoOnline Facility after the effective date of the
          Updated Terms constitutes your agreement to the Updated Terms. You
          should review these Terms of Service and any Updated Terms before
          continuing to use the PesoOnline Facility.
        </p>
        <p></p>
        <p>Miscellaneous</p>
        <p></p>
        <p>
          If any provision of these PesoOnline Terms of Service is held by any
          competent authority to be unenforceable or invalid, the relevant
          provision shall be modified so that it is enforceable in accordance
          with the original intent to the maximum extent permitted by applicable
          law. The validity and enforceability of the remaining provisions of
          these PesoOnline Terms of Service shall not be affected.
        </p>
        <p></p>
        <p>
          You agree that all documents or notices may be sent to you
          electronically via the email address you provide upon registration.
          Therefore, you acknowledge and confirm that it is your responsibility
          to update your current email address to the Company and that the
          Company shall not be liable for any claims of loss or damage arising
          from non-receipt of notices.
        </p>
        <p></p>
        <p>
          Subject to applicable law, all disclaimers, indemnities and exclusions
          in these PesoOnline Terms of Service shall survive termination of any
          Loan Agreement.
        </p>
        <p></p>
        <p>
          No single or partial exercise, failure to exercise or delay by us in
          exercising any right, power or remedy shall constitute a waiver or
          prejudice or prevent further exercise of such right, power or remedy
          arising under these PesoOnline Terms of Service or otherwise.
        </p>
        <p></p>
        <p>
          Unless otherwise expressly agreed in writing, these PesoOnline Terms
          of Service set forth the entire agreement between you and us with
          respect to your use of the PesoOnline Facility and supersede any and
          all representations, communications and prior agreements (written or
          oral) made by you or us.
        </p>
        <p></p>
        <p>
          These PesoOnline Terms of Service shall be governed by and construed
          in accordance with the laws of the Republic of the Philippines. All
          matters, claims or disputes arising out of or relating to these
          PesoOnline Terms of Service shall be subject to the exclusive
          jurisdiction of the courts of Pasig City.
        </p>
        <p></p>
        <p>
          The latest version of these Terms of Service was updated on August 22,
          2019
        </p>
        <p></p>
        <p>Lender Authorization</p>
        <p></p>
        <p>
          By accepting these Terms of Service, you understand that as a lender,
          you assume the risk of non-payment. In order to mitigate this risk,
          the Company may assign any non-performing loans* (“NPLs”) to a third
          party to the extent possible, in each case the Company will endeavor
          to allow you to receive the unpaid principal, interest and penalties
          to which you are entitled under the terms and conditions of the loan.
          If the loan or financial product is assigned, transferred, taken over,
          purchased or otherwise transferred to any other party, all loan and
          financial product records, transactions and any other information
          related to the issuance, history, basis and status of such loan or
          financial instrument (including personal and sensitive personal
          information) shall be shared, transferred and delivered to such third
          party.
        </p>
        <p></p>
        <p>
          Subject to the foregoing, you hereby authorize and appoint the Company
          as your authorized representative and agent to transfer, sell or
          otherwise dispose/transfer the NPL to a third party upon prior notice.
          The Company will notify you of such transfer via email and credit the
          unpaid principal, interest and penalties to your account of record.
        </p>
        <p></p>
        <p>User Acceptance</p>
        <p></p>
        <p>
          I confirm that I have read, understood and agree to the above
          PesoOnline Terms of Service.
        </p>
        <p></p>
        <p>
          By accepting these PesoOnline Terms of Service and the PesoOnline
          Privacy Policy, I expressly consent to Republic Act No. 10173, also
          known as the Data Privacy Act of 2012 and its implementing rules and
          regulations and other applicable confidentiality and data privacy laws
          of the Republic of the Philippines. I agree to release the Company,
          its officers, directors and shareholders from any liability, damages,
          actions, claims and proceedings arising out of the implementation or
          processing of my personal data related to my consent or authorization
          and my use of PesoOnline facilities in accordance with these Terms of
          Service.
        </p>
        <p></p>
        <p>Digital Signature</p>
        <p></p>
        <p>
          I have read these Terms of Service and agree to all of the above
          provisions and any amendments thereto. By clicking on the "Sign Up" or
          "Connect" or "Register" or "Submit" button on any social media or
          public platform linked to the PesoOnline Service, I understand that I
          am creating a digital signature, which I intend to have the same
          effect as my manual signature.
        </p>
        <p></p>
        <p>
          *Nonperforming loans are defined as any loan that is in default, as
          described in the applicable loan documents.
        </p>
        <p></p>
      </div>
    );
  }
}
export default ServiceAgreement;
